import React from "react"
import styled from "styled-components"
import Layout from "../../components/layout.js"
import SEO from "../../components/seo.js"
import { InPageNav } from "../../components/navbar.js"
import { McatHero } from "../../components/sections/heroSection.js"
import {
  ValuePropLeft,
  SwipeSection,
} from "../../components/sections/valuePropSection.js"
import TheCatch from "../../components/sections/catch.js"
// import {CtaCard} from '../../components/sections/sections.js';

//SVGs
import McatSvg from "../../images/super_thankyou.svg"

const Ul = styled.ul`
  list-style: none;
  padding: 0;
`
const Li = styled.li`
  margin: 0;
  padding: 0;
  text-indent: 0;
  list-style-type: 0;
`
const Bullet = styled.svg`
  display: inline-block;
  position: relative;
  top: 5px;
`
const Text = styled.span`
  font-size: 16px;
  &:before {
    content: "";
    display: inline-block;
    margin-right: 0.5em;
  }
`

// const SeeMore = styled.button`
//   display: block;
//   background: white;
//   padding: 5px 16px;
//   border-radius: 30px;
//   height: 15px;
//   margin: 0 auto;
//   outline: none;
//   margin-bottom: 50px;
// `
const List = ({ children }) => {
  return (
    <Li>
      <Bullet
        height="20"
        width="20"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        stroke="#F50057"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        style={{ display: "inline-block" }}
      >
        <path d="M4 10l4 4 8-9" />
      </Bullet>
      <Text>{children}</Text>
    </Li>
  )
}
const CASPer = ({ location }) => {
  const RedirectToPage = dest => {
    // navigate(dest)
  }

  return (
    <Layout>
      <SEO title="CASPer Test Prep." />
      <InPageNav
        currentPath={location.pathname}
        pageTitle="CASPer"
        navItems={[
          { link: "/casper", label: "Overview" },
          { link: "/casper/how_it_works", label: "How It Works" },
          { link: "/casper/schedule", label: "Class Schedule" },
          { link: "/casper/pricing", label: "Pricing" },
        ]}
        clickCallback={RedirectToPage}
      />
      <McatHero
        title="CASPer"
        body={[
          `Students prepare with us because we teach them how to think, what to think, and how to answer questions. Our prep. transforms their CASPer responses from "meh" to "shit, that's pretty good".`,
          <br />,
          <br />,
          `When you're finished with your prep., you'll be able to answer ANY CASPer question, any time, within time, and with confidence that you couldn't have done better.`,
        ]}
        ctaPrimary={{ label: "Buy Course", redirect: "/casper/schedule/" }}
        ctaSecondary={{ label: "Request Demo", redirect: "/contact" }}
        img={McatSvg}
        imgWidth="450px"
        imgStyle={{ top: "0px" }}
        backgroundColor="inherit"
      />
      <ValuePropLeft
        caption={"Ultimate"}
        title={"Curricula"}
        description={[
          `Our CASPer curricula is designed to:`,
          <Ul>
            <List>Have genuine conversations</List>
            <List>Teach you how to think</List>
            <List>Avoid memorization based learning</List>
            <List>Teach you how to communicate your responses well</List>
            <List>Give you tools to solve real-life problems</List>
          </Ul>,

          `Our focus is on improving the "real you", instead of teaching you some responses that you can memorize and use on your test day. This is why our students are rarely thrown off by questions on exam day.`,
        ]}
        figure="99th Percentile"
      />
      <ValuePropLeft
        caption={"Proven"}
        title={"Track Record"}
        figure={"95% Success"}
        description={[
          `95% of our students, who had a good GPA and application - i.e. their CASPer score was the only hurdle to receive an interview invite, received interview invites.`,
          <br />,
          <br />,
          `In fact, a significant percentage of our students, who have a comparatively low GPA or a not-so-stellar application still receive interview invites immediately, because of their CASPer scores. Those students who didn't receive invites immediately, when improved their application over the next year(s), received interview invites. `,
        ]}
      />
      <ValuePropLeft
        caption={"Unique"}
        title={"Step-by-step Instructions"}
        figure={"Quality"}
        description={[
          `Our prep. is uniquely successful because we don't rely on overly generalized principles. Certainly, one can say "do no harm" - but what does that mean? How do you apply that princple? What do you say in your response? How do you even say it?`,
          <br />,
          <br />,
          `Given your initial responses to our questions, we: `,
          <Ul>
            <List>Fix your wording</List>
            <List>Advise on how to frame your ideas</List>
            <List>
              Tell you the order in which you should mention your ideas
            </List>
            <List>Point out things you're missing, and should've said</List>
          </Ul>,
        ]}
      />
      <TheCatch />
      <SwipeSection wide float={"center"} title={["Course Perks"]} />
      />
      <ValuePropLeft
        caption={" "}
        title={[
          "Success ",
          <span style={{ color: "#F50057" }}>Guarantee</span>,
        ]}
        figure="CERTAINTY"
        description={[
          `We're confident in our ability to get you scoring well enough that, if you've: `,

          <Ul>
            <List>Followed our instructions</List>
            <List>Incoporated our feedback consistently on your responses</List>
            <List>Generally, did your work on time</List>
          </Ul>,
          `but didn't get an inverview invite-- you can retake the course, free of charge!`,
        ]}
      />
      <ValuePropLeft
        caption={" "}
        title={[
          <span style={{ color: "#F50057" }}>Quality</span>,
          ` Over Quantity`,
        ]}
        figure={`"GETTING IT"`}
        description={[
          "We want you to do super well, but not just for CASPer. We teach you with the aim that when you succeed, and receive an interview offer, you'll need some foundation to kill it on that too - so you're set for med.!",
          <br />,
          <br />,
          `That's one of the reasons why our prep. focusses on you naturally "getting it", as opposed to just drilling you with questions. Because, otherwise, guess what? You forget stuff over time when you're just "studying".`,
        ]}
      />
      <ValuePropLeft
        caption={" "}
        title={[
          "Think ",
          <span style={{ color: "#F50057" }}>BIG</span>,
          " picture",
        ]}
        figure="NEGOTIATIONS"
        description={[
          "There's a point to CASPer - med. schools want to know well how you interact with people. We take it a step further, and make it it a skill that you can use everyday or for specific things: getting a job, interviewing somewhere else, getting a promotion, having an actual difficult conversation when you are or aren't at fault. ",
          <br />,
          <br />,
          "Reguarly, we hear back from our students who tell us that they've done some amazing thing with their CASPer skill; negotiated with their supervisor for a higher pay, got hired for a rare position, or solved an incredibly difficult interpersonal conflict. ",
        ]}
      />
      <ValuePropLeft
        caption={" "}
        title={[
          <span style={{ color: "#F50057" }}>Crazy</span>,
          " Specific Feedback",
        ]}
        figure="SONAR-LIKE DETECTION"
        description="We're known about our detailed feedback and the specific changes you need to make. You'd be surprised - sometimes people use words or express sometimes casually, with good intentions, but some of them may mean something completely different to your CASPer proctor, and could cost you the interview invite. Don't worry though - we've got you; we won't let you do anything like that! "
      />
    </Layout>
  )
}

export default CASPer
